import type { PricingPlan, PricingPlanDTO } from '../ParagraphPricing.domain';

import isNil from 'lodash/isNil';
import { translate } from 'core/infrastructure/translator';

export const mapPricingPlans = (
  pricingPlans: PricingPlanDTO[],
  annual: boolean,
  langcode: string
): any => {
  if (!pricingPlans) return [];
  return pricingPlans.map((item: PricingPlanDTO) => {
    const { plan } = item;
    const pricingPlan: PricingPlan = { name: '', card: {} };

    if (plan.name) {
      pricingPlan.name = plan.name;
      pricingPlan.card.title = {
        text: plan.name,
        color: plan.nameColor,
      };
    }

    if (plan.description) {
      pricingPlan.card.description = {
        text: plan.description.value,
        color: plan.color,
      };
    }

    pricingPlan.card.price = {
      text: annual
        ? (plan.annualPrice as string)
        : isNil(plan.monthlyPrice)
        ? (plan.annualPrice as string)
        : (plan.monthlyPrice as string),
      color: plan.color,
    };

    pricingPlan.card.priceText = {
      text: annual
        ? (plan.annualTextPrice as string)
        : isNil(plan.monthlyPrice)
        ? (plan.annualTextPrice as string)
        : (plan.monthlyTextPrice as string),
      color: plan.priceColor,
    };

    if (plan.monthlyTextPrice) {
      pricingPlan.card.recurring = {
        text: plan.monthlyTextPrice as string,
        color: plan.priceColor,
      };
    }

    if (!isNil(annual)) {
      pricingPlan.card.priceDescription = {
        text: annual ? plan.annualPriceDescription : plan.monthlyPriceDescription,
        color: plan.color,
      };
    }

    if (plan.borderColor) {
      pricingPlan.card.border = plan.borderColor;
    }

    if (plan.backgroundColor) {
      pricingPlan.card.backgroundColor = plan.backgroundColor;
    }

    pricingPlan.card.button = {
      value: plan.annualUrlText ? plan.annualUrlText : translate(langcode, 'pricing.choose'),
      variant: plan.annualUrlStyle ? plan.annualUrlStyle.variant : 'tertiary',
      href: buttonUrl(item, annual),
      dataCy: 'CardsPlan' + pricingPlan.name,
    };

    if (plan.people) {
      pricingPlan.card.people = plan.people;
      pricingPlan.card.peopleBackground = plan.peopleBackground;
    }

    if (plan.highlightBanner) {
      pricingPlan.card.highlightBanner = {
        text: plan.highlightBanner,
        color: plan.ribbonTextColor,
        type: plan.ribbonTextType,
      };
      pricingPlan.card.ribbonBackgroundColor = plan.ribbonBackgroundColor;
      pricingPlan.card.ribbonBorderRadius = plan.ribbonBorderRadius;
      pricingPlan.card.ribbonIcon = {
        name: plan.ribbonIcon?.sprite,
        sheet: plan.ribbonIcon?.sheet,
        ribbonIconColor: plan.ribbonIconColor,
      };
    }

    if (plan.priceFromAPI) {
      pricingPlan.card.priceFromAPI = plan.priceFromAPI;
    }

    if (plan.includes) {
      const feats: any = {};
      feats.title = translate(langcode, 'pricing.includes');
      feats.icon = {
        name: 'Success',
        color: 'black',
      };
      feats.includes = plan.includes.map((feat: any) => ({
        text: feat,
      }));

      pricingPlan.feats = feats;
    }

    if (plan.planType) {
      pricingPlan.type = plan.planType;
    }

    if (plan.icon) {
      pricingPlan.card.icon = {
        name: plan.icon.sprite,
        sheet: plan.icon.sheet,
        color: plan.iconColor,
      };
    }

    return pricingPlan;
  });
};

const buttonUrl = (item: PricingPlanDTO, annual: boolean): string => {
  const { plan } = item;
  if (plan.contactUrl) {
    return plan.contactUrl;
  }

  return annual ? plan.annualUrl : plan.monthlyUrl;
};
